// Main entry

// Default
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

// App
import App from "./App";

// Redux
import { store, persistor } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";

// Context
import { ToastProvider } from "./context/ToastContext";
import { LoaderProvider } from "./context/LoaderContext";
import { DEFAULT_TENANT_ID } from "./utils/Constants";

const root = ReactDOM.createRoot(document.getElementById("root"));

const getTenantIdFromUrl = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get("eDispatched_account_id");
};

let tenantId = getTenantIdFromUrl();

if (!tenantId) {
  console.error("tenant_id not found in URL. Using default");

  tenantId = DEFAULT_TENANT_ID;
}

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <LoaderProvider>
        <ToastProvider>
          <App tenantId={tenantId} />
        </ToastProvider>
      </LoaderProvider>
    </PersistGate>
  </Provider>
);

reportWebVitals();
