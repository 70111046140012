import axios from "axios";
import { BASE_API_URL } from "./Constants";
import { store } from "../store/index";

/**
 * Retrieves the access token and tenant_id from the store.
 * @returns {Object} The access token and tenant_id.
 */
const getStoreData = () => {
  const { accessToken } = store.getState().auth;
  const tenant_id = store.getState().business.tenantId;

  return { accessToken, tenant_id };
};

const waitForTenantId = () => {
  return new Promise((resolve) => {
    const unsubscribe = store.subscribe(() => {
      const { tenantId } = store.getState().business;
      if (tenantId) {
        unsubscribe();
        resolve(tenantId);
      }
    });
  });
};

/**
 * Makes an HTTP request using Axios.
 * @param {Function} method - The Axios method to use (e.g., axios.post).
 * @param {string} URL - The endpoint URL.
 * @param {Object} [body] - The request payload.
 * @returns {Promise<Object>} The response data or an error.
 */
const makeRequest = async (method, URL, body = null) => {
  const { accessToken, tenant_id } = getStoreData();
  if (!tenant_id) {
    await waitForTenantId();
  }
  const { accessToken: updatedAccessToken, tenant_id: updatedTenantId } =
    getStoreData();
  try {
    const headers = {
      "Content-Type": "application/json",
      tenant_id: updatedTenantId, // Ensure tenant_id is added to headers
    };
    if (updatedAccessToken) {
      headers["Authorization"] = `Bearer ${updatedAccessToken}`;
    }

    const response = await axios({
      method,
      url: BASE_API_URL + URL,
      data: body,
      headers: headers,
    });

    return response.data;
  } catch (error) {
    console.error("Error in request:", error);
    throw error.response; // Re-throw the error after logging it
  }
};

/**
 * Makes a POST request.
 * @param {string} URL - The endpoint URL.
 * @param {Object} [body={}] - The request payload.
 * @returns {Promise<Object>} The response data.
 */
export const fetchPOSTRequest = async (URL, body = {}) => {
  return makeRequest("post", URL, body);
};

/**
 * Makes a PUT request.
 * @param {string} URL - The endpoint URL.
 * @param {Object} [body={}] - The request payload.
 * @returns {Promise<Object>} The response data.
 */
export const fetchPUTRequest = async (URL, body = {}) => {
  return makeRequest("put", URL, body);
};

/**
 * Makes a DELETE request.
 * @param {string} URL - The endpoint URL.
 * @param {Object} [body={}] - The request payload.
 * @returns {Promise<Object>} The response data.
 */
export const fetchDELETERequest = async (URL, body = {}) => {
  return makeRequest("delete", URL, body);
};

/**
 * Makes a GET request.
 * @param {string} URL - The endpoint URL.
 * @returns {Promise<Object>} The response data.
 */
export const fetchGETRequest = async (URL) => {
  return makeRequest("get", URL);
};
