import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tenantId: null, // Initialize tenantId to null
  business: {},
};

const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    setTenantId: (state, action) => {
      state.tenantId = action.payload;
    },
  },
});

export const { setTenantId } = businessSlice.actions;
export default businessSlice.reducer;
