import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { formatCurrency } from "../../utils/FormatCurrency";
import { fetchGETRequest, fetchPOSTRequest } from "../../utils/services";
import PaymentFormComponent from "../../components/payment/PaymentFormComponent";

function PayRemainingBookingBalancePage() {
  const [isLoading, setIsLoading] = useState(true);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [pendingPayment, setPendingPayment] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const bookingReference = query.get("booking_reference");
  const customerId = query.get("cid");
  const bookingId = query.get("booking_id");
  const pendingPaymentId = query.get("ppid");

  useEffect(() => {
    const fetchBooking = async () => {
      try {
        const response = await fetchGETRequest(
          `/customer/get-booking/${customerId}/${bookingReference}/${bookingId}`
        );

        if (response.statusCode === 200) {
          setBookingDetails(response.data);
          const pendingPayment = response.data.booking.scheduled_payments.find(
            (payment) => payment.scheduled_payment_id === pendingPaymentId
          );
          setPendingPayment(pendingPayment);
        } else {
          console.error("Error retrieving booking:", response);
        }
      } catch (error) {
        console.error("Error retrieving booking:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBooking();
  }, [customerId, bookingReference, bookingId, pendingPaymentId]);

  if (isLoading) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (!bookingDetails || !pendingPayment) {
    return (
      <Container>
        <Box mt={3}>
          <Typography variant="h6" color="error">
            Booking or pending payment not found.
          </Typography>
        </Box>
      </Container>
    );
  }

  const { booking, departureAirport, arrivalAirport } = bookingDetails;

  const formatDateAndTime = (date) => {
    if (!date) return "Invalid date";
    return new Date(date).toLocaleString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
    });
  };

  const formatDate = (date) => {
    if (!date) return "Invalid date";
    return new Date(date).toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const mapTripType = (tripType) => {
    switch (tripType) {
      case "one_way":
        return "One Way";
      case "round_trip":
        return "Round Trip";
      case "multi_city":
        return "Multi City";
      default:
        return tripType;
    }
  };

  const mapStatus = (status) => {
    switch (status) {
      case "confirmed":
        return "Confirmed";
      case "confirmed_with_balance_due":
        return "Confirmed (Balance Due)";
      case "pending":
        return "Pending";
      case "cancelled":
        return "Cancelled";
      case "completed":
        return "Completed";
      default:
        return status;
    }
  };

  const totalDueNow = pendingPayment.amount + pendingPayment.tax;

  const handleSubmit = async (paymentTokenResponse) => {
    setIsLoading(true);

    if (paymentTokenResponse && paymentTokenResponse.payment_token_id) {
      await handlePaymentToken(
        paymentTokenResponse.payment_token_id,
        paymentTokenResponse.cc_last_four
      );
    } else {
      console.error("Payment token is missing.");
      setIsLoading(false);
    }
  };

  const handlePaymentToken = async (payment_token_id, cc_last_four) => {
    const payload = {
      payment_token: payment_token_id,
      cc_last_four: cc_last_four,
      customer_id: customerId,
      booking_id: bookingId,
      scheduled_payment_id: pendingPaymentId,
    };

    try {
      const response = await fetchPOSTRequest(
        "/customer/complete-scheduled-payment",
        payload
      );

      if (response.statusCode === 200) {
        navigate(
          `/booking-confirmation/?cid=${response.data.customer.customerId}&booking_reference=${response.data.booking.booking_reference}&bookingId=${response.data.booking.bookingId}`
        );
      } else {
        console.log(JSON.stringify(response));
        console.error("Error with payment:", response);
        // Stay on the same page if response is 400 or 500
      }
    } catch (error) {
      console.error("Error with pending payment:", error);
      // Stay on the same page if there's an error
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Box mb={5} mt={7}>
        <Typography variant="h4">
          Your booking is confirmed but you have an outstanding payment.
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {/* Main Content */}
        <Grid item xs={12} md={8}>
          <Card>
            <CardHeader
              title={`Booking Reference Number: ${"\u00A0"}${"\u00A0"}${
                booking.booking_reference
              }`}
              style={{ backgroundColor: "#f5f5f5" }}
            />
            <CardContent>
              <Grid container spacing={3} alignItems="center" ml={3}>
                <Grid item xs={3} container>
                  <Typography align="left" variant="subtitle1">
                    Tail Number:
                  </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-start">
                  <Typography align="left">{booking.tail_number}</Typography>
                </Grid>
                <Grid item xs={3} container>
                  <Typography align="left" variant="subtitle1">
                    Trip Type:
                  </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-start">
                  <Typography align="left">
                    {mapTripType(booking.trip_type)}
                  </Typography>
                </Grid>
                <Grid item xs={3} container>
                  <Typography align="left" variant="subtitle1">
                    Departure:
                  </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-start">
                  <Typography align="left">
                    {departureAirport?.airport_name}
                  </Typography>
                </Grid>
                <Grid item xs={3} container>
                  <Typography align="left" variant="subtitle1">
                    Arrival:
                  </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-start">
                  <Typography align="left">
                    {arrivalAirport?.airport_name}
                  </Typography>
                </Grid>

                <Grid item xs={3} container>
                  <Typography align="left" variant="subtitle1">
                    Name:
                  </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-start">
                  <Typography align="left">
                    {booking.customer?.first_name} {booking.customer?.last_name}
                  </Typography>
                </Grid>
                <Grid item xs={3} container>
                  <Typography align="left" variant="subtitle1">
                    Email:
                  </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-start">
                  <Typography align="left">
                    {booking.customer?.email}
                  </Typography>
                </Grid>
                <Grid item xs={3} container>
                  <Typography align="left" variant="subtitle1">
                    Phone Number:
                  </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-start">
                  <Typography align="left">
                    {booking.customer?.phone_number}
                  </Typography>
                </Grid>

                <Grid item xs={3} container>
                  <Typography align="left" variant="subtitle1">
                    Total Travelers:
                  </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-start">
                  <Typography align="left">
                    {booking.number_of_passengers}
                  </Typography>
                </Grid>

                <Grid item xs={3} container>
                  <Typography align="left" variant="subtitle1">
                    Status:
                  </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-start">
                  <Typography align="left">
                    {mapStatus(booking.status)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <PaymentFormComponent handleSubmit={handleSubmit} />

          <Typography align="left" mt={5} mb={10} variant="subtitle1">
            Thank you for booking with us. We look forward to flying with you!
          </Typography>
        </Grid>

        {/* Sidebar */}
        <Grid item xs={12} md={4}>
          <Card sx={{ position: "sticky", top: "50px" }}>
            <CardHeader
              title="Price Summary"
              style={{ backgroundColor: "#f5f5f5" }}
            />
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography>Total Trip Price:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography align="right">
                    {formatCurrency(booking.base_price)} + tax
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    align="center"
                    color="textSecondary"
                    mt={5}
                    variant="h6"
                  >
                    Paid On {formatDate(booking.payments[0].created_at)}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography>Amount:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography align="right">
                    {formatCurrency(booking.payments[0].amount)}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography>Tax:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography align="right">
                    {formatCurrency(booking.payments[0].tax)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Total Paid:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography align="right">
                    {formatCurrency(booking.amount_paid)}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    align="center"
                    color="textSecondary"
                    mt={5}
                    variant="h6"
                  >
                    Due by {formatDate(pendingPayment.due_date)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Amount:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography align="right">
                    {formatCurrency(pendingPayment.amount)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Tax:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography align="right">
                    {formatCurrency(pendingPayment.tax)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Total Due Now:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography align="right">
                    {formatCurrency(totalDueNow)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography align="center" color="textSecondary" mt={3}>
                    We will email you a receipt once the payment is processed.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default PayRemainingBookingBalancePage;
