import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_API_URL } from "../../../utils/Constants";
import { format, parse, isValid } from "date-fns";

const formatDate = (date) => {
  try {
    return format(date, "MM/dd/yyyy");
  } catch (error) {
    return date;
  }
};

const parseDate = (dateString) => {
  try {
    const parsedDate = parse(dateString, "MM/dd/yyyy", new Date());
    return isValid(parsedDate) ? parsedDate : new Date();
  } catch (error) {
    return new Date();
  }
};

const autoFormatDate = (value) => {
  const cleanedValue = value.replace(/\D/g, "");

  if (cleanedValue.length >= 8) {
    const month = cleanedValue.slice(0, 2);
    const day = cleanedValue.slice(2, 4);
    const year = cleanedValue.slice(4, 8);
    return `${month}/${day}/${year}`;
  } else if (cleanedValue.length >= 4) {
    const month = cleanedValue.slice(0, 2);
    const day = cleanedValue.slice(2, 4);
    return `${month}/${day}/${cleanedValue.slice(4)}`;
  } else if (cleanedValue.length >= 2) {
    const month = cleanedValue.slice(0, 2);
    return `${month}/${cleanedValue.slice(2)}`;
  } else {
    return cleanedValue;
  }
};

const CustomerDriversLicenseUploadInitialPage = ({
  bookingDetails,
  token,
  handleNext,
}) => {
  const [loading, setLoading] = useState(false);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [driversLicenseNumber, setDriversLicenseNumber] = useState("");
  const [driversLicenseExpiration, setDriversLicenseExpiration] = useState("");

  const [formData, setFormData] = useState({
    street_address: "",
    unit: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    weight: "",
  });

  const [extractedData, setExtractedData] = useState({
    street_address: "",
    unit: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (
      formData.street_address &&
      formData.city &&
      formData.state &&
      formData.country &&
      formData.zip &&
      formData.weight
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
    console.log(isFormValid);
  }, [formData]);

  const handleDLUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const uploadFormData = new FormData();
    uploadFormData.append("document_type", "drivers_license");
    uploadFormData.append("file", file);
    uploadFormData.append("customer_id", bookingDetails.customer_id);

    setLoading(true);
    try {
      const url = `${BASE_API_URL}/document/customer/upload-customer-document`;

      const response = await axios.post(url, uploadFormData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const { data } = response.data;
        const { textractData } = data;

        setFormData((prevState) => ({
          ...prevState,
          street_address: textractData.address || prevState.street_address,
          city: textractData.cityInAddress || prevState.city,
          state: textractData.stateInAddress || prevState.state,
          zip: textractData.zipCodeInAddress || prevState.zip,
        }));

        setExtractedData({
          street_address: textractData.address || "",
          unit: "", // If unit is also extracted, set it here
          city: textractData.cityInAddress || "",
          state: textractData.stateInAddress || "",
          country: "", // If country is also extracted, set it here
          zip: textractData.zipCodeInAddress || "",
        });

        setDriversLicenseNumber(textractData.documentNumber || "");
        setDriversLicenseExpiration(textractData.expirationDate || "");
        setUploadCompleted(true);
      }
    } catch (error) {
      console.error("Failed to upload document.", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = async () => {
    let payload = {
      customer_id: bookingDetails.customer_id,
      booking_id: bookingDetails.booking_id,
      weight: formData.weight,
    };

    // Check if address fields are different from the extracted ones
    if (
      formData.street_address !== extractedData.street_address ||
      formData.city !== extractedData.city ||
      formData.state !== extractedData.state ||
      formData.country !== extractedData.country ||
      formData.zip !== extractedData.zip
    ) {
      payload = {
        ...payload,
        street_address: formData.street_address,
        unit: formData.unit,
        city: formData.city,
        state: formData.state,
        country: formData.country,
        zip: formData.zip,
      };
    }

    try {
      const url = `${BASE_API_URL}/customer/update-profile`;
      const response = await axios.put(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        console.log("Form submitted successfully");
        handleNext(); // Move to the next step in the stepper
      }
    } catch (error) {
      console.error("Failed to submit form", error);
    }
  };

  return (
    <>
      <Typography variant="h6" gutterBottom pb={1}>
        Add details for {bookingDetails.customer.first_name}{" "}
        {bookingDetails.customer.last_name}
      </Typography>

      <Typography variant="body1" gutterBottom pb={1}>
        We need to collect your home address as per TSA and CBP regulations. We
        can automatically extract the address from{" "}
        {bookingDetails.customer.first_name}'s US Driver's License.
      </Typography>
      <Typography variant="body1" gutterBottom pb={1}>
        Please review the extracted address and make any necessary corrections.
        You can also manually enter the information below.
      </Typography>
      <Typography variant="body1" gutterBottom pb={1}>
        We will also need your weight for flight planning.
      </Typography>
      <Stack spacing={2} justifyContent="center" sx={{ my: 2 }}>
        <LoadingButton
          variant="contained"
          component="label"
          loading={loading}
          fullWidth
        >
          Upload Driver's License
          <input
            type="file"
            accept="image/*"
            capture="environment"
            hidden
            onChange={handleDLUpload}
          />
        </LoadingButton>
      </Stack>
      <Divider />
      {uploadCompleted && (
        <Stack spacing={2} justifyContent="center" sx={{ my: 2 }}>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Drivers License Number: {driversLicenseNumber}
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Drivers License Expiration: {driversLicenseExpiration}
            </Typography>
          </Box>
        </Stack>
      )}
      <Stack spacing={2} justifyContent="center" sx={{ my: 2 }}>
        <Box component="form" autoComplete="on">
          <TextField
            fullWidth
            required
            label="Street Address"
            variant="outlined"
            margin="normal"
            name="street_address"
            value={formData.street_address}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Unit"
            variant="outlined"
            margin="normal"
            name="unit"
            value={formData.unit}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            required
            label="City"
            variant="outlined"
            margin="normal"
            name="city"
            value={formData.city}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            required
            label="State"
            variant="outlined"
            margin="normal"
            name="state"
            value={formData.state}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            required
            label="Country"
            variant="outlined"
            margin="normal"
            name="country"
            value={formData.country}
            onChange={handleChange}
          />

          <TextField
            fullWidth
            required
            label="Zip"
            variant="outlined"
            margin="normal"
            name="zip"
            value={formData.zip}
            onChange={handleChange}
          />

          <TextField
            fullWidth
            required
            label="Weight (lbs)"
            variant="outlined"
            margin="normal"
            name="weight"
            value={formData.weight}
            onChange={handleChange}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          />
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              disabled={!isFormValid}
              onClick={handleFormSubmit}
            >
              Submit
            </Button>
            <Button fullWidth size="large" variant="outlined">
              Back
            </Button>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default CustomerDriversLicenseUploadInitialPage;
