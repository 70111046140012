import React from "react";
import { Container } from "@mui/system";
import { styled } from "@mui/material/styles";
import { Helmet } from "react-helmet-async";
import { Typography } from "@mui/material";
import { isMobile } from "react-device-detect";
import UploadDocumentFromInvitationDesktopComponent from "../../components/document-upload/UploadDocumentFromInvitationDesktopComponent";

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",

  display: "flex",
  justifyContent: "center",
  flexDirection: "column",

  minHeight: "100vh", // Ensure it takes full viewport height
}));

function UploadDocumentFromInvitationPage() {
  return (
    <React.Fragment>
      <Helmet>
        <title>eDispatched | Upload Documents</title>
      </Helmet>

      <Container>
        <StyledContent>
          <Typography variant="h4" gutterBottom>
            Upload Documents
          </Typography>

          <UploadDocumentFromInvitationDesktopComponent />
        </StyledContent>
      </Container>
    </React.Fragment>
  );
}

export default UploadDocumentFromInvitationPage;
