// Constants

// API url
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

// MX Merchant
export const MX_PAYMENT_FORM_URL = process.env.REACT_APP_MX_PAYMENT_FORM_URL;
export const MX_MERCHANT_CONNECTION_AIR_TPN_NUMBER =
  process.env.REACT_APP_MX_MERCHANT_CONNECTION_AIR_TPN_NUMBER;
export const MX_MERCHANT_CONNECTION_AIR_TOKEN =
  process.env.REACT_APP_MX_MERCHANT_CONNECTION_AIR_TOKEN;

export const DEFAULT_TENANT_ID = process.env.REACT_APP_DEFAULT_TENANT_ID;

// All routes
export const LOGIN = "login";
export const SIGNUP = "register";

export const DASHBOARD = "dashboard";
export const ADD_PLANE = "add-plane";

export const BOOKINGS = "bookings";
export const STATS = "stats";
export const FLIGHT_LOGS = "flight-logs";
export const CALENDAR = "calendar";
export const MAINTENANCE_LOGS = "maintenance-logs";
export const USERS = "users";
export const PAYMENTS = "payments";
export const BUSINESS_SETTINGS = "business-settings";
export const PROFILE = "profile";

// Helmet title
export const LOGIN_HELMET = "eDispatched | Login";
export const SIGNUP_HELMET = "eDispatched | Register";
export const PAGE404_HELMET = "eDispatched | 404 Page Not Found";
export const DASHBOARD_HELMET = "eDispatched";

// US States
export const US_STATES = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
  "American Samoa",
  "District of Columbia",
  "Federated States of Micronesia",
  "Guam",
  "Marshall Islands",
  "Northern Mariana Islands",
  "Palau",
  "Puerto Rico",
  "Virgin Islands",
];
