import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  TextField,
  Grid,
  Button,
  Card,
  CardContent,
  CardHeader,
  Box,
  IconButton,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { fetchPOSTRequest } from "../../utils/services";
import PaymentFormComponent from "../../components/payment/PaymentFormComponent";
import { useToast } from "../../context/ToastContext";
import { formatCurrency } from "../../utils/FormatCurrency";
import {
  startLoading,
  stopLoading,
} from "../../store/features/BookingDataSlice";
import { useDispatch } from "react-redux";

function CreateBookingPage() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { setToast } = useToast();
  const {
    selectedPlane,
    departure,
    departureAirportName,
    arrival,
    arrivalAirportName,
    tripType,
    selectedDepartureDate,
    selectedArrivalDate,
    priceData,
  } = state;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [phoneCountryCode, setPhoneCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [nationality, setNationality] = useState("");
  const [extraPassengers, setExtraPassengers] = useState([]);
  const [isEditDisabled, setIsEditDisabled] = useState(false);
  const [isCustomerEditDisabled, setIsCustomerEditDisabled] = useState(false);

  const [isFormValid, setIsFormValid] = useState(false);
  const [isCustomerSaved, setIsCustomerSaved] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      firstName &&
      lastName &&
      email &&
      confirmEmail &&
      phoneNumber &&
      nationality &&
      validateEmail(email) &&
      email === confirmEmail &&
      validatePhoneNumber(phoneNumber) &&
      isCustomerSaved
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [
    firstName,
    lastName,
    email,
    confirmEmail,
    phoneNumber,
    nationality,
    isCustomerSaved,
  ]);

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    return /^\d{10,15}$/.test(phoneNumber);
  };

  const handleAddPassenger = () => {
    setExtraPassengers([
      ...extraPassengers,
      { firstName: "", lastName: "", nationality: "", isNew: true },
    ]);
    setIsEditDisabled(false);
  };

  const handleDeletePassenger = (index) => {
    const updatedPassengers = extraPassengers.filter((_, i) => i !== index);
    setExtraPassengers(updatedPassengers);
  };

  const handlePassengerChange = (index, field, value) => {
    const updatedPassengers = extraPassengers.map((passenger, i) => {
      if (i === index) {
        return { ...passenger, [field]: value.trim() };
      }
      return passenger;
    });
    setExtraPassengers(updatedPassengers);
  };

  const handleSavePassengers = () => {
    const updatedPassengers = extraPassengers.map((passenger) => ({
      ...passenger,
      isNew: false,
    }));
    setExtraPassengers(updatedPassengers);
    setIsEditDisabled(true);
  };

  const handleEditPassengers = () => {
    setIsEditDisabled(false);
  };

  const handleSaveCustomer = () => {
    setIsCustomerEditDisabled(true);

    setIsCustomerSaved(true);
  };

  const handleEditCustomer = () => {
    setIsCustomerEditDisabled(false);

    setIsCustomerSaved(false);
  };

  const handlePaymentToken = async (payment_token_id, cc_last_four) => {
    const payload = {
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      email: email.trim(),
      phone_country_code: phoneCountryCode,
      phone_number: phoneNumber.trim(),
      nationality: nationality.trim(),
      tail_number: selectedPlane,
      trip_type: tripType,
      trip_departure_airport_code: departure,
      trip_arrival_airport_code: arrival,
      trip_departure_flight_time: selectedDepartureDate.toISOString(),
      flight_segments: [
        {
          departure_airport_code: departure,
          arrival_airport_code: arrival,
          departing_flight_time: selectedDepartureDate.toISOString(),
          trip_leg_number: 1,
        },
        ...(tripType === "round_trip"
          ? [
              {
                departure_airport_code: arrival,
                arrival_airport_code: departure,
                departing_flight_time: selectedArrivalDate.toISOString(),
                trip_leg_number: 2,
              },
            ]
          : []),
      ],
      number_of_passengers: 1 + extraPassengers.length,
      extra_passengers: extraPassengers.map((passenger) => ({
        first_name: passenger.firstName.trim(),
        last_name: passenger.lastName.trim(),
        nationality: passenger.nationality.trim(),
      })),
      payment_token: payment_token_id,
      cc_last_four: cc_last_four,
    };

    try {
      const response = await fetchPOSTRequest(
        "/customer/create-booking",
        payload
      );

      if (response.statusCode === 201) {
        navigate(
          `/booking-confirmation/?cid=${response.data.customer.customer_id}&booking_reference=${response.data.booking.booking_reference}&booking_id=${response.data.booking.booking_id}`
        );
      } else if (response.statusCode === 400) {
        dispatch(stopLoading());

        setToast({
          open: true,
          message: response.Message || "Error creating booking",
          severity: "error",
        });
      } else {
      }
    } catch (error) {
      console.error("Error creating booking:", error);
      dispatch(stopLoading());

      const errorMessage =
        error?.data?.Message || error.message || "Error creating booking";
      setToast({
        open: true,
        message: errorMessage,
        severity: "error",
      });
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleSubmit = async (paymentTokenResponse) => {
    dispatch(startLoading());

    if (paymentTokenResponse && paymentTokenResponse.payment_token_id) {
      await handlePaymentToken(
        paymentTokenResponse.payment_token_id,
        paymentTokenResponse.cc_last_four
      );
    } else {
      console.error("Payment token is missing.");
      dispatch(stopLoading());
    }
  };

  console.log(priceData);

  //  const handleButtonClick = () => {
  //     if (!isLoading) {
  //       dispatch(startLoading());
  //       handlePaymentToken("test_payment_token_id", "1234");
  //     }
  //   };

  const formatDateAndTime = (date) => {
    if (!date) return "Invalid date";
    return new Date(date).toLocaleString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
    });
  };

  const formatDate = (date) => {
    if (!date) return "Invalid date";
    return new Date(date).toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <Container>
      <Box mb={3}>
        <Typography variant="h4" component="h1">
          Create Booking
        </Typography>
      </Box>

      <Grid container spacing={3}>
        {/* Main Content */}
        <Grid item xs={12} md={8}>
          <Card>
            <CardHeader
              title="Trip Summary"
              style={{ backgroundColor: "#f5f5f5" }}
            />
            <CardContent>
              <Grid container spacing={3} alignItems="center" ml={3}>
                <Grid item xs={3} container>
                  <Typography align="left" variant="subtitle1">
                    Tail Number:
                  </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-start">
                  <Typography align="left">{selectedPlane}</Typography>
                </Grid>
                <Grid item xs={3} container>
                  <Typography align="left" variant="subtitle1">
                    Departure:
                  </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-start">
                  <Typography align="left">{departureAirportName}</Typography>
                </Grid>
                <Grid item xs={3} container>
                  <Typography align="left" variant="subtitle1">
                    Arrival:
                  </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-start">
                  <Typography align="left">{arrivalAirportName}</Typography>
                </Grid>
                <Grid item xs={3} container>
                  <Typography align="left" variant="subtitle1">
                    Departure Date:
                  </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-start">
                  <Typography align="left">
                    {formatDateAndTime(selectedDepartureDate)}
                  </Typography>
                </Grid>
                {tripType === "round_trip" && (
                  <>
                    <Grid item xs={3} container>
                      <Typography align="left" variant="subtitle1">
                        Return Date:
                      </Typography>
                    </Grid>
                    <Grid item xs={8} container justifyContent="flex-start">
                      <Typography align="left">
                        {formatDateAndTime(selectedArrivalDate)}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>

          <Card sx={{ marginTop: 3 }}>
            <CardHeader
              title="Customer Details"
              style={{ backgroundColor: "#f5f5f5" }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value.trim())}
                    fullWidth
                    disabled={isCustomerEditDisabled}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value.trim())}
                    fullWidth
                    disabled={isCustomerEditDisabled}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value.trim())}
                    fullWidth
                    disabled={isCustomerEditDisabled}
                    required
                    error={!!email && !validateEmail(email)} // Convert to boolean
                    helperText={
                      email && !validateEmail(email)
                        ? "Enter a valid email address"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Confirm Email"
                    value={confirmEmail}
                    onChange={(e) => setConfirmEmail(e.target.value.trim())}
                    fullWidth
                    disabled={isCustomerEditDisabled}
                    required
                    error={!!confirmEmail && email !== confirmEmail}
                    helperText={
                      confirmEmail && email !== confirmEmail
                        ? "Email addresses do not match"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <PhoneInput
                    country={"us"}
                    value={phoneNumber}
                    onChange={(phone, countryData) => {
                      setPhoneNumber(phone.trim());
                      setPhoneCountryCode(`+${countryData.dialCode}`);
                    }}
                    inputStyle={{
                      fontSize: "16px",
                      width: "100%",
                      height: "56px", // Ensure this matches the height of TextField inputs
                      borderRadius: "4px",
                      borderColor:
                        phoneNumber && !validatePhoneNumber(phoneNumber)
                          ? "red"
                          : "",
                    }}
                    inputProps={{
                      required: true,
                      disabled: isCustomerEditDisabled,
                    }}
                    specialLabel="Phone Number *"
                  />

                  {phoneNumber && !validatePhoneNumber(phoneNumber) && (
                    <Typography variant="body2" color="error">
                      Enter a valid phone number with country code (e.g.,
                      +1234567890)
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Nationality"
                    value={nationality}
                    onChange={(e) => setNationality(e.target.value.trim())}
                    fullWidth
                    disabled={isCustomerEditDisabled}
                    required
                  />
                </Grid>
              </Grid>
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={
                    isCustomerEditDisabled
                      ? handleEditCustomer
                      : handleSaveCustomer
                  }
                  disabled={
                    !firstName ||
                    !lastName ||
                    !email ||
                    !confirmEmail ||
                    !phoneNumber ||
                    !nationality ||
                    !validateEmail(email) ||
                    email !== confirmEmail ||
                    !validatePhoneNumber(phoneNumber)
                  }
                >
                  {isCustomerEditDisabled ? "Edit Customer" : "Save Customer"}
                </Button>
              </Box>
            </CardContent>
          </Card>

          <Card sx={{ marginTop: 3 }}>
            <CardHeader
              title="Add any extra Passengers"
              style={{ backgroundColor: "#f5f5f5" }}
              action={
                <Button
                  onClick={handleAddPassenger}
                  variant="text"
                  color="primary"
                  disabled={!isCustomerEditDisabled}
                >
                  Add Passenger
                </Button>
              }
            />
            <CardContent>
              {extraPassengers.map((passenger, index) => (
                <Grid
                  container
                  spacing={2}
                  key={index}
                  sx={{ marginBottom: 2 }}
                >
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="First Name"
                      value={passenger.firstName}
                      onChange={(e) =>
                        handlePassengerChange(
                          index,
                          "firstName",
                          e.target.value
                        )
                      }
                      fullWidth
                      disabled={isEditDisabled && !passenger.isNew}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Last Name"
                      value={passenger.lastName}
                      onChange={(e) =>
                        handlePassengerChange(index, "lastName", e.target.value)
                      }
                      fullWidth
                      disabled={isEditDisabled && !passenger.isNew}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="Nationality"
                      value={passenger.nationality}
                      onChange={(e) =>
                        handlePassengerChange(
                          index,
                          "nationality",
                          e.target.value
                        )
                      }
                      fullWidth
                      disabled={isEditDisabled && !passenger.isNew}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <IconButton
                      onClick={() => handleDeletePassenger(index)}
                      disabled={isEditDisabled}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              {extraPassengers.length > 0 && (
                <Box mt={2} display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={
                      isEditDisabled
                        ? handleEditPassengers
                        : handleSavePassengers
                    }
                    disabled={extraPassengers.some(
                      (passenger) =>
                        !passenger.firstName ||
                        !passenger.lastName ||
                        !passenger.nationality
                    )}
                  >
                    {isEditDisabled ? "Edit Passengers" : "Save Passengers"}
                  </Button>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Sidebar */}
        <Grid item xs={12} md={4}>
          {priceData?.grandTotalDueLater === 0 ? (
            <Card
              sx={{
                position: "sticky",
                top: "50px",
              }}
            >
              <CardHeader
                title="Price Summary"
                style={{ backgroundColor: "#f5f5f5" }}
              />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography>Base Price:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      {formatCurrency(priceData?.tripBasePrice || 0)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Tax:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      {formatCurrency(priceData?.tripTax || 0)}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} mt={1}>
                    <Typography fontWeight={600} variant="h6">
                      Grand Total:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} mt={1}>
                    <Typography fontWeight={600} variant="h6" align="right">
                      {formatCurrency(
                        priceData?.grandTotalDueAtTimeOfBooking || 0
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ) : (
            <Card
              sx={{
                position: "sticky",
                top: "50px",
              }}
            >
              <CardHeader
                title="Price Summary"
                style={{ backgroundColor: "#f5f5f5" }}
              />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography>Trip Base Price:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      {formatCurrency(priceData?.tripBasePrice || 0)}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography>Trip Tax:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      {formatCurrency(priceData?.tripTax || 0)}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography>Trip Grand Total:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      {formatCurrency(priceData?.tripGrandTotal || 0)}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      align="center"
                      color="textSecondary"
                      mb={3}
                      mt={5}
                    >
                      Your booking allows you to pay a portion of the total
                      amount today and the rest later.
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography>Base Due Today:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      {formatCurrency(
                        priceData?.basePriceDueAtTimeOfBooking || 0
                      )}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography>Tax Due Today:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      {formatCurrency(priceData?.taxDueAtTimeOfBooking || 0)}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} mt={1}>
                    <Typography fontWeight={600} variant="h6">
                      Total Due Today:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} mt={1}>
                    <Typography fontWeight={600} variant="h6" align="right">
                      {formatCurrency(
                        priceData?.grandTotalDueAtTimeOfBooking || 0
                      )}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      align="center"
                      color="textSecondary"
                      mb={3}
                      mt={5}
                    >
                      The following amount will be due on at a later date.
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Base Due Later:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      {formatCurrency(priceData?.basePriceDueLater || 0)}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography>Tax Due Later:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      {formatCurrency(priceData?.taxDueLater || 0)}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography>Total Due Later:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      {formatCurrency(priceData?.grandTotalDueLater || 0)}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography>Due on:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      {formatDate(priceData?.grandTotalDueLaterDate)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align="center" color="textSecondary" mt={3}>
                      We will automatically send you a reminder email for the
                      payment.
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <PaymentFormComponent
            handleSubmit={handleSubmit}
            isParentFormValid={isFormValid}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography align="left" mt={2} color="textSecondary">
            Your payment receipt will be emailed to you.
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography align="left" gutterBottom mb={10} color="textSecondary">
            eDispatched will process your payment on behalf of the operator. By
            clicking the "Proceed to Payment" button, you agree to our Terms and
            Conditions.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CreateBookingPage;
