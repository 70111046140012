// Routes

// Default
import { Routes, Route, Router } from "react-router-dom";

// Pages

import Page404 from "./pages/404";
import UploadDocumentFromInvitationPage from "./pages/document-upload/UploadDocumentFromInvitationPage";
import DocumentUploadCompletePage from "./components/document-upload/DocumentUploadCompletePage";
import SearchBookingPage from "./pages/bookings/SearchBookingPage";
import CreateBookingPage from "./pages/bookings/CreateBookingPage";
import BookingConfirmationPage from "./pages/bookings/BookingConfirmationPage";
import FreedomToDesign from "./pages/bookings/FreedomToDesign";
import PayRemainingBookingBalancecPage from "./pages/bookings/PayRemainingBookingBalancePage";

const MainRouter = () => {
  return (
    <Routes>
      {/* <Route path={"*"} element={<Page404 />} /> */}
      <Route path={"*"} element={<SearchBookingPage />} />
      <Route path={"/create-booking"} element={<CreateBookingPage />} />
      <Route path={"/freedom-to-design"} element={<FreedomToDesign />} />
      <Route
        path={"/booking-confirmation"}
        element={<BookingConfirmationPage />}
      />

      <Route
        path={"/pay-booking-balance"}
        element={<PayRemainingBookingBalancecPage />}
      />
      <Route
        path={"/document-upload"}
        element={<UploadDocumentFromInvitationPage />}
      />
      <Route
        path={"/document-upload-completed"}
        element={<DocumentUploadCompletePage />}
      />
    </Routes>
  );
};
export default MainRouter;
