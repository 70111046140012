import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { endOfDay, isWithinInterval, parseISO, startOfDay } from "date-fns";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchGETRequest, fetchPOSTRequest } from "../../utils/services";

import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

import { styled } from "@mui/system";
import { useToast } from "../../context/ToastContext";
import { formatCurrency } from "../../utils/FormatCurrency";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";

const DayWrapper = styled("div")(
  ({ theme, isPartiallyAvailable, isGreyedOut }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.primary,
    borderRadius: "50%",
    width: 36,
    height: 36,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: isPartiallyAvailable
      ? "yellow"
      : isGreyedOut
      ? "#e0e0e0"
      : "inherit",
    "&:hover": {
      backgroundColor: isPartiallyAvailable
        ? "yellow"
        : isGreyedOut
        ? "#e0e0e0"
        : theme.palette.action.hover,
    },
  })
);

const FullWidthDatePicker = styled(DatePicker)(({ theme }) => ({
  width: "100%",
  "& .MuiTextField-root": {
    width: "100%",
  },
}));

function SearchBookingPage() {
  const [businessData, setBusinessData] = useState(null);
  const [departure, setDeparture] = useState("");
  const [arrival, setArrival] = useState("");
  const [tripType, setTripType] = useState("one_way");
  const [selectedDepartureDate, setSelectedDepartureDate] = useState(null);
  const [selectedDepartureTime, setSelectedDepartureTime] = useState("");
  const [selectedArrivalDate, setSelectedArrivalDate] = useState(null);
  const [selectedArrivalTime, setSelectedArrivalTime] = useState("");
  const [selectedPlane, setSelectedPlane] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setToast } = useToast();

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await fetchGETRequest(`/customer/get-business-data`);
        if (response && response.data && response.data.length > 0) {
          setBusinessData(response.data[0]); // Assuming response.data is an array with one business
        }
      } catch (error) {
        console.error("Error fetching business data:", error);
      }
    };

    fetchBookings();
  }, []);

  if (!businessData) {
    return <p>Loading...</p>;
  }

  const {
    name,
    street,
    unit,
    city,
    state,
    zip_code,
    phone_number,
    fleet,
    destinations,
    timezone: businessTimezone,
    schedules,
  } = businessData;
  const handleSearch = async () => {
    setLoading(true);

    const departureDateTime = zonedTimeToUtc(
      new Date(
        selectedDepartureDate.getFullYear(),
        selectedDepartureDate.getMonth(),
        selectedDepartureDate.getDate(),
        selectedDepartureTime.split(":")[0],
        0
      ),
      businessTimezone
    );

    let arrivalDateTime = null;
    if (
      tripType === "round_trip" &&
      selectedArrivalDate &&
      selectedArrivalTime
    ) {
      arrivalDateTime = zonedTimeToUtc(
        new Date(
          selectedArrivalDate.getFullYear(),
          selectedArrivalDate.getMonth(),
          selectedArrivalDate.getDate(),
          selectedArrivalTime.split(":")[0],
          0
        ),
        businessTimezone
      );
    }

    const departureAirport = destinations.find(
      (dest) => dest.airport_code === departure
    );
    const arrivalAirport = destinations.find(
      (dest) => dest.airport_code === arrival
    );

    const flight_segments = [
      {
        departure_airport_code: departure,
        arrival_airport_code: arrival,
        departing_flight_time: departureDateTime.toISOString(),
        trip_leg_number: 1,
      },
    ];

    if (tripType === "round_trip" && arrivalDateTime) {
      flight_segments.push({
        departure_airport_code: arrival,
        arrival_airport_code: departure,
        departing_flight_time: arrivalDateTime.toISOString(),
        trip_leg_number: flight_segments.length + 1,
      });
    }

    const payload = {
      tail_number: selectedPlane,
      trip_type: tripType,
      flight_segments,
    };

    try {
      const response = await fetchPOSTRequest(
        "/customer/get-booking-price",
        payload
      );
      setLoading(false);

      if (response && response.data) {
        if (response.data.available) {
          navigate("/create-booking", {
            state: {
              selectedPlane,
              departure,
              departureAirportName: departureAirport?.airport_name || "",
              arrival,
              arrivalAirportName: arrivalAirport?.airport_name || "",
              tripType,
              selectedDepartureDate: departureDateTime,
              selectedArrivalDate: arrivalDateTime,
              priceData: response.data,
            },
          });
        } else {
          setToast({
            open: true,
            severity: "error",
            message: response.data.message,
          });
        }
      }
    } catch (error) {
      setLoading(false);
      setToast({
        open: true,
        severity: "error",
        message: "Error fetching booking price. Please try again later.",
      });
      console.error("Error fetching booking price:", error);
    }
  };

  const filteredDepartureDestinations = destinations.filter(
    (dest) => dest.show_in_departure_list && dest.airport_code !== arrival
  );

  const filteredArrivalDestinations = destinations.filter(
    (dest) => dest.show_in_arrival_list && dest.airport_code !== departure
  );

  const isFormValid =
    selectedPlane &&
    departure &&
    arrival &&
    selectedDepartureDate &&
    selectedDepartureTime &&
    (tripType === "one_way" || (selectedArrivalDate && selectedArrivalTime));

  const shouldDisableDate = (date) => {
    if (!schedules || schedules.length === 0) {
      return false; // Enable all dates if schedules data is not available
    }

    // Check if the plane is unavailable for all 24 hours of the day
    return schedules.every((schedule) => {
      const start = utcToZonedTime(
        parseISO(schedule.booking_start_time),
        businessTimezone
      );
      const end = utcToZonedTime(
        parseISO(schedule.booking_end_time),
        businessTimezone
      );
      if (!isValid(start) || !isValid(end)) {
        return false;
      }
      return (
        isWithinInterval(date, {
          start: startOfDay(start),
          end: endOfDay(end),
        }) && (end - start) / (1000 * 60 * 60) >= 24
      );
    });
  };

  const haversineDistance = (lat1, lon1, lat2, lon2) => {
    const toRadians = (deg) => (deg * Math.PI) / 180;
    const R = 6371; // Radius of the Earth in kilometers

    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in kilometers
  };

  const shouldDisableHours = (date, time) => {
    const now = new Date();
    const [formattedHour, period] = time.split(" ");
    let hour = parseInt(formattedHour);
    if (period === "PM" && hour !== 12) {
      hour += 12;
    } else if (period === "AM" && hour === 12) {
      hour = 0;
    }

    // Default to today if no date is provided
    const zonedDate = utcToZonedTime(date, businessTimezone);
    const effectiveDate = new Date(
      zonedDate.getFullYear(),
      zonedDate.getMonth(),
      zonedDate.getDate(),
      hour
    );

    // Disable past hours for today, whether the date is provided or defaulted to now
    if (isSameDay(effectiveDate, now) && effectiveDate < now) {
      return true;
    }

    // If date is null, no need to check schedules as we're only concerned with disabling past hours
    if (!date) {
      return false;
    }

    // If there are no schedules, all future hours are enabled
    if (!schedules || schedules.length === 0) {
      return false;
    }

    // Check against schedules to disable hours that are within booked intervals
    return schedules.some((schedule) => {
      const start = utcToZonedTime(
        parseISO(schedule.booking_start_time),
        businessTimezone
      );
      const end = utcToZonedTime(
        parseISO(schedule.booking_end_time),
        businessTimezone
      );

      if (!isValid(start) || !isValid(end)) {
        return false;
      }

      // Calculate flight time to destination and back
      const departureAirport = destinations.find(
        (dest) => dest.airport_code === departure
      );
      const arrivalAirport = destinations.find(
        (dest) => dest.airport_code === arrival
      );

      if (!departureAirport || !arrivalAirport || !selectedPlane) {
        return false;
      }

      const selectedPlaneData = fleet.find(
        (plane) => plane.tail_number === selectedPlane
      );

      if (!selectedPlaneData) {
        return false;
      }

      const distance = haversineDistance(
        departureAirport.airport_latitude,
        departureAirport.airport_longitude,
        arrivalAirport.airport_latitude,
        arrivalAirport.airport_longitude
      );

      const cruiseSpeedKts = selectedPlaneData.cruise_speed_kts;
      const cruiseSpeedKmh = cruiseSpeedKts * 1.852; // Convert knots to km/h
      const flightDurationHours = distance / cruiseSpeedKmh;
      const bufferTimeMs = flightDurationHours * 60 * 60 * 1000;

      return (
        isWithinInterval(effectiveDate, { start, end }) ||
        isWithinInterval(effectiveDate, {
          start: new Date(start.getTime() - bufferTimeMs),
          end: new Date(end.getTime() + bufferTimeMs),
        })
      );
    });
  };

  function isSameDay(d1, d2) {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  }

  // Utility function to validate dates
  const isValid = (date) => date instanceof Date && !isNaN(date);

  // Generate time options for dropdown (e.g., "00:00", "01:00", ..., "23:00")
  // Generate time options for dropdown in 12-hour format with AM and PM
  const timeOptions = Array.from({ length: 24 }, (_, hour) => {
    const period = hour < 12 ? "AM" : "PM";
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    return `${formattedHour.toString().padStart(2, "0")}:00 ${period}`;
  });

  const renderDay = (day, _value, DayComponentProps) => {
    const isPartiallyAvailable = schedules.some((schedule) => {
      const start = parseISO(schedule.booking_start_time);
      const end = parseISO(schedule.booking_end_time);
      if (!isValid(start) || !isValid(end)) {
        return false;
      }
      return (
        isWithinInterval(day, { start, end }) &&
        (end - start) / (1000 * 60 * 60) < 24
      );
    });

    const isGreyedOut = shouldDisableDate(day);

    return (
      <DayWrapper
        {...DayComponentProps}
        isPartiallyAvailable={isPartiallyAvailable}
        isGreyedOut={isGreyedOut}
      >
        {day.getDate()}
      </DayWrapper>
    );
  };

  return (
    <Container>
      <h1>Search Booking</h1>

      <h2>Fleet</h2>
      <Grid container spacing={3}>
        {fleet.map((plane, index) => (
          <Grid item key={index} xs={3} sm={3} md={3}>
            <Card
              onClick={() => setSelectedPlane(plane.tail_number)}
              style={{
                cursor: "pointer",
                border:
                  selectedPlane === plane.tail_number
                    ? "2px solid #479DE1"
                    : "1px solid #ccc",
                boxShadow:
                  selectedPlane === plane.tail_number
                    ? "0 0 10px #479DE1"
                    : "0 0 5px #ccc",
              }}
            >
              <CardContent>
                <Grid>
                  <Box mb={1}>
                    <Typography variant="h6">{`${plane.tail_number}`}</Typography>
                  </Box>
                  <Box mb={1}>
                    <Typography>{` ${plane.year} ${plane.make} ${plane.model}`}</Typography>
                  </Box>
                  <Box mb={1}>
                    <Typography>
                      {`Passenger Seats: ${plane.passenger_seats || "N/A"}`}
                    </Typography>
                  </Box>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <h2>Book a Flight</h2>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            select
            label="Trip Type"
            value={tripType}
            onChange={(e) => setTripType(e.target.value)}
            fullWidth
            disabled={!selectedPlane}
          >
            <MenuItem value="one_way">One Way</MenuItem>
            <MenuItem value="round_trip">Round Trip</MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: "16px" }}>
        <Grid item xs={12} sm={4}>
          <TextField
            select
            label="Select Departure"
            value={departure}
            onChange={(e) => setDeparture(e.target.value)}
            fullWidth
            disabled={!selectedPlane}
          >
            <MenuItem value="">Select Departure</MenuItem>
            {filteredDepartureDestinations.map((dest, index) => (
              <MenuItem key={index} value={dest.airport_code}>
                {dest.airport_name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            select
            label="Select Arrival"
            value={arrival}
            onChange={(e) => setArrival(e.target.value)}
            fullWidth
            disabled={!selectedPlane}
          >
            <MenuItem value="">Select Arrival</MenuItem>
            {filteredArrivalDestinations.map((dest, index) => (
              <MenuItem key={index} value={dest.airport_code}>
                {dest.airport_name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: "16px" }}>
        <Grid item xs={12} sm={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FullWidthDatePicker
              label="Select Departure Date"
              value={selectedDepartureDate}
              onChange={(newValue) => setSelectedDepartureDate(newValue)}
              disabled={!selectedPlane || !departure || !arrival}
              minDate={new Date()}
              shouldDisableDate={shouldDisableDate}
              renderDay={renderDay}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            select
            label="Select Departure Time"
            value={selectedDepartureTime}
            onChange={(e) => setSelectedDepartureTime(e.target.value)}
            fullWidth
            disabled={!selectedPlane || !departure || !arrival}
          >
            <MenuItem value="">Select Departure Time</MenuItem>
            {timeOptions.map((time, index) => (
              <MenuItem
                key={index}
                value={time}
                disabled={shouldDisableHours(selectedDepartureDate, time)}
              >
                {time}{" "}
                {shouldDisableHours(selectedDepartureDate, time) &&
                  "(Not available)"}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      {tripType === "round_trip" && (
        <>
          <Grid container spacing={2} style={{ marginTop: "16px" }}>
            <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Select Return Date"
                  value={selectedArrivalDate}
                  onChange={(newValue) => setSelectedArrivalDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  disabled={!selectedPlane || !departure || !arrival}
                  minDate={selectedDepartureDate || new Date()}
                  shouldDisableDate={shouldDisableDate}
                  renderDay={renderDay}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                select
                label="Select Return Time"
                value={selectedArrivalTime}
                onChange={(e) => setSelectedArrivalTime(e.target.value)}
                fullWidth
                disabled={!selectedPlane || !departure || !arrival}
              >
                <MenuItem value="">Select Return Time</MenuItem>
                {timeOptions.map((time, index) => (
                  <MenuItem
                    key={index}
                    value={time}
                    disabled={shouldDisableHours(selectedArrivalDate, time)}
                  >
                    {time}{" "}
                    {shouldDisableHours(selectedArrivalDate, time) &&
                      "(Not available)"}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </>
      )}

      <Grid container justifyContent="left" style={{ marginTop: "50px" }}>
        <Grid item xs={6} sm={6}>
          <Button
            variant="contained"
            id="continue-button"
            color="primary"
            fullWidth
            onClick={handleSearch}
            style={{ borderRadius: "8px" }}
            disabled={!isFormValid || loading}
            size="large"
          >
            {loading ? <CircularProgress size={24} /> : "Continue"}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default SearchBookingPage;
