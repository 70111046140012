import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  error: null,
  bookingDetails: {}, // Add more initial states as needed
};

const bookingDataSlice = createSlice({
  name: "bookingData",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    setBookingDetails: (state, action) => {
      state.bookingDetails = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
});

export const {
  startLoading,
  stopLoading,
  setError,
  setBookingDetails,
  clearError,
} = bookingDataSlice.actions;

export default bookingDataSlice.reducer;
