import { Box, Step, StepContent, StepLabel, Stepper } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_API_URL } from "../../utils/Constants";
import DocumentUploadWelcomePage from "./DocumentUploadWelcomePage";
import CustomerDriversLicenseUploadInitialPage from "./customer/CustomerDriversLicenseUploadInitialPage";
import CustomerPassportUploadInitialPage from "./customer/CustomerPassportUploadInitialPage";
import PassengerPassportUploadInitialPage from "./passenger/PassengerPassportUploadInitialPage";

const UploadDocumentFromInvitationComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [bookingDetails, setBookingDetails] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);

  const query = new URLSearchParams(location.search);
  const token = query.get("token");
  const bookingReference = query.get("booking_reference");
  const customer_id = query.get("cid");
  const booking_id = query.get("booking_id");

  useEffect(() => {
    if (bookingReference && token && customer_id && booking_id) {
      axios
        .get(
          `${BASE_API_URL}/customer/get-booking/${customer_id}/${bookingReference}/${booking_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (
            response.data &&
            response.data.data &&
            response.data.data.booking
          ) {
            setBookingDetails(response.data.data.booking);
          }
        })
        .catch((error) => {
          console.error("Failed to fetch booking details.", error);
        });
    }
  }, [bookingReference, token, customer_id, booking_id]);

  const handleNext = () => {
    const totalSteps = bookingDetails
      ? bookingDetails.passengers.length + 2
      : 0;
    if (currentStep >= totalSteps) {
      navigate("/document-upload-completed", {
        state: {
          bookingDetails: bookingDetails,
          steps: steps,
        },
      });
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const customerName = bookingDetails?.customer?.first_name || "Customer";
  const passengerNames =
    bookingDetails?.passengers?.map((p) => p.first_name) || [];

  const steps = [
    "Welcome to Document Upload",
    `${customerName} Details`,
    `${customerName} Passport`,
    ...passengerNames.map((name) => `${name} Passport`),
  ];

  const renderStepContent = (stepIndex) => {
    if (stepIndex === 0)
      return <DocumentUploadWelcomePage handleNext={handleNext} />;
    if (stepIndex === 1) {
      return (
        <CustomerDriversLicenseUploadInitialPage
          bookingDetails={bookingDetails}
          token={token}
          handleNext={handleNext}
        />
      );
    }
    if (stepIndex === 2) {
      return (
        <CustomerPassportUploadInitialPage
          bookingDetails={bookingDetails}
          token={token}
          handleNext={handleNext}
        />
      );
    }
    return (
      <PassengerPassportUploadInitialPage
        bookingDetails={bookingDetails}
        token={token}
        handleNext={handleNext}
        passenger={bookingDetails.passengers[stepIndex - 3]}
      />
    );
  };

  return (
    <React.Fragment>
      {bookingDetails && (
        <Box>
          <Stepper activeStep={currentStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={index} completed={index < currentStep}>
                <StepLabel>{label}</StepLabel>
                <StepContent>{renderStepContent(index)}</StepContent>
              </Step>
            ))}
          </Stepper>
        </Box>
      )}
    </React.Fragment>
  );
};

export default UploadDocumentFromInvitationComponent;
