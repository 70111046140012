import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_API_URL } from "../../../utils/Constants";
import { format, parse, isValid } from "date-fns";

const formatDate = (date) => {
  try {
    return format(new Date(date), "MM/dd/yyyy");
  } catch (error) {
    return date;
  }
};

const parseTextractDate = (dateString) => {
  try {
    return parse(dateString, "dd MMM yyyy", new Date());
  } catch (error) {
    return new Date();
  }
};

const autoFormatDate = (value) => {
  const cleanedValue = value.replace(/\D/g, "");

  if (cleanedValue.length >= 8) {
    const month = cleanedValue.slice(0, 2);
    const day = cleanedValue.slice(2, 4);
    const year = cleanedValue.slice(4, 8);
    return `${month}/${day}/${year}`;
  } else if (cleanedValue.length >= 4) {
    const month = cleanedValue.slice(0, 2);
    const day = cleanedValue.slice(2, 4);
    return `${month}/${day}/${cleanedValue.slice(4)}`;
  } else if (cleanedValue.length >= 2) {
    const month = cleanedValue.slice(0, 2);
    return `${month}/${cleanedValue.slice(2)}`;
  } else {
    return cleanedValue;
  }
};

const extractCountryFromMRZ = (mrz) => {
  const countryCode = mrz.slice(2, 5);
  return countryCode === "USA" ? "USA" : countryCode;
};

const PassengerPassportUploadInitialPage = ({
  bookingDetails,
  token,
  passenger,
  handleNext,
}) => {
  const [loading, setLoading] = useState(false);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [passportNumber, setPassportNumber] = useState("");
  const [passportExpiration, setPassportExpiration] = useState("");

  const [formData, setFormData] = useState({
    passport_number: "",
    expiration_date: "",
    date_of_birth: "",
    date_of_issue: "",
    country_of_issue: "",
  });

  const [extractedData, setExtractedData] = useState({
    passport_number: "",
    expiration_date: "",
    date_of_birth: "",
    date_of_issue: "",
    country_of_issue: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]:
        name === "expiration_date" ||
        name === "date_of_birth" ||
        name === "date_of_issue"
          ? autoFormatDate(value)
          : value,
    }));
  };

  useEffect(() => {
    if (
      formData.passport_number &&
      formData.expiration_date &&
      formData.date_of_birth &&
      formData.date_of_issue &&
      formData.country_of_issue
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formData]);

  const handlePassportUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const uploadFormData = new FormData();
    uploadFormData.append("document_type", "passport");
    uploadFormData.append("file", file);
    uploadFormData.append("customer_id", bookingDetails?.customer_id);
    uploadFormData.append("passenger_id", passenger?.passenger_id);

    setLoading(true);
    try {
      const url = `${BASE_API_URL}/document/customer/upload-passenger-document`;

      const response = await axios.post(url, uploadFormData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const { data } = response.data;
        const { textractData } = data;

        const country = extractCountryFromMRZ(textractData.mrzCode);

        setFormData((prevState) => ({
          ...prevState,
          passport_number:
            textractData.documentNumber || prevState.passport_number,
          expiration_date: formatDate(
            parseTextractDate(
              textractData.expirationDate || prevState.expiration_date
            )
          ),
          date_of_birth: formatDate(
            parseTextractDate(
              textractData.dateOfBirth || prevState.date_of_birth
            )
          ),
          date_of_issue: formatDate(
            parseTextractDate(
              textractData.dateOfIssue || prevState.date_of_issue
            )
          ),
          country_of_issue: country || prevState.country_of_issue,
        }));

        setExtractedData({
          passport_number: textractData.documentNumber || "",
          expiration_date: formatDate(
            parseTextractDate(textractData.expirationDate || "")
          ),
          date_of_birth: formatDate(
            parseTextractDate(textractData.dateOfBirth || "")
          ),
          date_of_issue: formatDate(
            parseTextractDate(textractData.dateOfIssue || "")
          ),
          country_of_issue: country || "",
        });

        setPassportNumber(textractData.documentNumber || "");
        setPassportExpiration(textractData.expirationDate || "");
        setUploadCompleted(true);
      }
    } catch (error) {
      console.error("Failed to upload document.", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = async () => {
    let payload = {
      passenger_id: passenger.passenger_id,
      booking_id: bookingDetails.booking_id,
    };

    if (
      formData.passport_number !== extractedData.passport_number ||
      formData.expiration_date !== extractedData.expiration_date ||
      formData.date_of_birth !== extractedData.date_of_birth ||
      formData.date_of_issue !== extractedData.date_of_issue ||
      formData.country_of_issue !== extractedData.country_of_issue
    ) {
      payload = {
        ...payload,
        passport_number: formData.passport_number,
        expiration_date: formatDate(
          parse(formData.expiration_date, "MM/dd/yyyy", new Date())
        ),
        date_of_birth: formatDate(
          parse(formData.date_of_birth, "MM/dd/yyyy", new Date())
        ),
        date_of_issue: formatDate(
          parse(formData.date_of_issue, "MM/dd/yyyy", new Date())
        ),
        country_of_issue: formData.country_of_issue,
      };
    }

    try {
      // const url = `${BASE_API_URL}/passenger/update-profile`;
      // const response = await axios.put(url, payload, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });

      // if (response.status === 200) {
      //   console.log("Form submitted successfully");
      //   handleNext(); // Move to the next step in the stepper
      // }
      handleNext(); // Move to the next step in the stepper
    } catch (error) {
      console.error("Failed to submit form", error);
    }
  };

  return (
    <>
      <Typography variant="h6" gutterBottom pb={1}>
        Upload {passenger?.first_name} {passenger?.last_name}'s passport
      </Typography>

      <Typography variant="body1" gutterBottom pb={1}>
        Passport upload is required for your international flight.
      </Typography>
      <Typography variant="body1" gutterBottom pb={1}>
        Upload a clear image of {passenger?.first_name}'s passport page with the
        photo and personal information. This will help us verify your identity
        and ensure that your information is accurate for your trip and for CBP
        regulations.
      </Typography>
      <Stack spacing={2} justifyContent="center" sx={{ my: 2 }}>
        <LoadingButton
          variant="contained"
          component="label"
          loading={loading}
          fullWidth
        >
          Upload Passport
          <input
            type="file"
            accept="image/*"
            capture="environment"
            hidden
            onChange={handlePassportUpload}
          />
        </LoadingButton>
      </Stack>
      <Divider />
      {uploadCompleted && (
        <Stack spacing={2} justifyContent="center" sx={{ my: 2 }}>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Passport Number: {passportNumber}
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Passport Expiration: {passportExpiration}
            </Typography>
          </Box>
        </Stack>
      )}
      <Stack spacing={2} justifyContent="center" sx={{ my: 2 }}>
        <Box component="form" autoComplete="on">
          <TextField
            fullWidth
            required
            label="Passport Number"
            variant="outlined"
            margin="normal"
            name="passport_number"
            value={formData.passport_number}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            required
            label="Expiration Date"
            variant="outlined"
            margin="normal"
            name="expiration_date"
            value={formData.expiration_date}
            onChange={handleChange}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          />
          <TextField
            fullWidth
            required
            label="Date of Birth"
            variant="outlined"
            margin="normal"
            name="date_of_birth"
            value={formData.date_of_birth}
            onChange={handleChange}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          />
          <TextField
            fullWidth
            required
            label="Date of Issue"
            variant="outlined"
            margin="normal"
            name="date_of_issue"
            value={formData.date_of_issue}
            onChange={handleChange}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          />
          <TextField
            fullWidth
            required
            label="Country of Issue"
            variant="outlined"
            margin="normal"
            name="country_of_issue"
            value={formData.country_of_issue}
            onChange={handleChange}
          />
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              disabled={!isFormValid}
              onClick={handleFormSubmit}
            >
              Submit
            </Button>
            <Button fullWidth size="large" variant="outlined">
              Back
            </Button>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default PassengerPassportUploadInitialPage;
