import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { GlobalStyles } from "@mui/material";
import theme from "./utils/theme/Theme";
import MainRouter from "./routes";
import ToastAlertComponent from "./components/toast/ToastComponent";
import { setTenantId } from "./store/features/BusinessSlice";

function App({ tenantId }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (tenantId) {
      dispatch(setTenantId(tenantId));
    }
  }, [tenantId, dispatch]);

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{
            body: {
              fontFamily: theme.typography.fontFamily,
              margin: 0,
              padding: 0,
              boxSizing: "border-box",
            },
          }}
        />
        <ToastAlertComponent />
        <Router>
          <MainRouter />
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
