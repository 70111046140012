import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  Box,
  Link,
} from "@mui/material";

const DocumentUploadWelcomePage = ({ handleNext }) => {
  const [consentGiven, setConsentGiven] = useState(false);

  const handleConsentChange = (event) => {
    setConsentGiven(event.target.checked);
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Welcome to the document upload process!
        </Typography>
        <Typography variant="body1" gutterBottom>
          We need to gather some information from you including your address,
          passport number, and other details. Don't worry, we can make the
          process easier by scanning your documents.
        </Typography>
        <Typography variant="body1" gutterBottom>
          eDispatched, LLC is our partner and will be collecting data on behalf
          of the business.
        </Typography>
        <Box mt={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={consentGiven}
                onChange={handleConsentChange}
                name="consent"
                color="primary"
              />
            }
            label={
              <Typography variant="body2">
                I agree to the collection and processing of my personal
                information by eDispatched, LLC on behalf of the business, in
                accordance with the{" "}
                <Link
                  href="https://edispatched.com/privacy-policy.html"
                  target="_blank"
                  rel="noopener"
                >
                  privacy policy
                </Link>
                .
              </Typography>
            }
          />
        </Box>
      </CardContent>
      <CardActions>
        <Button
          onClick={handleNext}
          variant="contained"
          color="primary"
          fullWidth
          disabled={!consentGiven}
        >
          Get Started
        </Button>
      </CardActions>
    </Card>
  );
};

export default DocumentUploadWelcomePage;
