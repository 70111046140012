import React from "react";
import { useLocation } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  Container,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Helmet } from "react-helmet-async";

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",

  display: "flex",
  justifyContent: "center",
  flexDirection: "column",

  minHeight: "100vh", // Ensure it takes full viewport height
}));

const DocumentUploadCompletePage = () => {
  const location = useLocation();
  const { state } = location;

  if (!state) {
    return (
      <React.Fragment>
        <Helmet>
          <title>eDispatched | Upload Documents</title>
        </Helmet>
        <StyledContent>
          <Box>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Thank you for uploading your documents!
                </Typography>
                <Typography variant="body1">
                  We have received your documents and will process them shortly.
                  Your flight is confirmed. For any questions, please contact
                  the business.
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </StyledContent>
      </React.Fragment>
    );
  }

  const { bookingDetails, steps } = state;

  return (
    <React.Fragment>
      <Helmet>
        <title>eDispatched | Upload Documents</title>
      </Helmet>
      <StyledContent>
        <Box>
          <Stepper activeStep={steps.length - 1} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={index} completed={index < steps.length - 1}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Thank you for uploading your documents!
              </Typography>
              <Typography variant="body1">
                We have received your documents and will process them shortly.
                Your flight is confirmed. For any questions, please contact the
                business.
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </StyledContent>
    </React.Fragment>
  );
};

export default DocumentUploadCompletePage;
