import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Grid,
  Button,
  Card,
  CardContent,
  CardHeader,
  Box,
  CircularProgress,
} from "@mui/material";
import { fetchGETRequest } from "../../utils/services";
import { formatCurrency } from "../../utils/FormatCurrency";

function BookingConfirmationPage() {
  const navigate = useNavigate();
  const [bookingDetails, setBookingDetails] = useState(null);
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const bookingReference = query.get("booking_reference");
  const customer_id = query.get("cid");
  const booking_id = query.get("booking_id");

  useEffect(() => {
    if (bookingReference && customer_id && booking_id) {
      const fetchBookingDetails = async () => {
        try {
          const response = await fetchGETRequest(
            `/customer/get-booking/${customer_id}/${bookingReference}/${booking_id}`
          );
          setBookingDetails(response?.data);
        } catch (error) {
          console.error("Error fetching booking details", error);
        }
      };

      fetchBookingDetails();
    }
  }, [bookingReference, customer_id, booking_id]);

  if (!bookingDetails) {
    return (
      <Container>
        <Typography variant="h6">Loading booking details...</Typography>
        <CircularProgress />
      </Container>
    );
  }

  const {
    booking,
    customer,
    flight_segments,
    passengers,
    amount_due_later_date,
  } = bookingDetails;

  const formatDateAndTime = (date) => {
    if (!date) return "Invalid date";
    return new Date(date).toLocaleString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
    });
  };

  const formatDate = (date) => {
    if (!date) return "Invalid date";
    return new Date(date).toLocaleString("en-US", {
      // weekday: "short",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const calculateTotalTax = (payments) => {
    return payments.reduce((total, payment) => total + (payment.tax || 0), 0);
  };

  const totalTax = calculateTotalTax(bookingDetails.booking.payments || []);

  const mapTripType = (tripType) => {
    switch (tripType) {
      case "one_way":
        return "One Way";
      case "round_trip":
        return "Round Trip";
      case "multi_city":
        return "Multi City";
      default:
        return tripType;
    }
  };

  const mapStatus = (status) => {
    switch (status) {
      case "confirmed":
        return "Confirmed";
      case "confirmed_with_balance_due":
        return "Confirmed (Balance Due)";
      case "pending":
        return "Pending";
      case "cancelled":
        return "Cancelled";
      case "completed":
        return "Completed";
      default:
        return status;
    }
  };

  return (
    <Container>
      <Box mb={5} mt={7}>
        <Typography variant="h4" component="h1">
          Your trip is confirmed!
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {/* Main Content */}
        <Grid item xs={12} md={8}>
          <Card>
            <CardHeader
              title={`Booking Reference Number: ${"\u00A0"}${"\u00A0"}${bookingReference}`}
              style={{ backgroundColor: "#f5f5f5" }}
            />
            <CardContent>
              <Grid container spacing={3} alignItems="center" ml={3}>
                <Grid item xs={3} container>
                  <Typography align="left" variant="subtitle1">
                    Tail Number:
                  </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-start">
                  <Typography align="left">
                    {bookingDetails.booking.tail_number}
                  </Typography>
                </Grid>
                <Grid item xs={3} container>
                  <Typography align="left" variant="subtitle1">
                    Trip Type:
                  </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-start">
                  <Typography align="left">
                    {mapTripType(bookingDetails.booking.trip_type)}
                  </Typography>
                </Grid>
                <Grid item xs={3} container>
                  <Typography align="left" variant="subtitle1">
                    Departure:
                  </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-start">
                  <Typography align="left">
                    {bookingDetails.departureAirport?.airport_name}
                  </Typography>
                </Grid>
                <Grid item xs={3} container>
                  <Typography align="left" variant="subtitle1">
                    Arrival:
                  </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-start">
                  <Typography align="left">
                    {bookingDetails.arrivalAirport?.airport_name}
                  </Typography>
                </Grid>
                <Grid item xs={3} container>
                  <Typography align="left" variant="subtitle1">
                    Total Travelers:
                  </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-start">
                  <Typography align="left">
                    {bookingDetails.booking.number_of_passengers}
                  </Typography>
                </Grid>

                <Grid item xs={3} container>
                  <Typography align="left" variant="subtitle1">
                    Status:
                  </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-start">
                  <Typography align="left">
                    {mapStatus(bookingDetails.booking.status)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card sx={{ marginTop: 5 }}>
            <CardHeader
              title="Customer Details"
              style={{ backgroundColor: "#f5f5f5" }}
            />
            <CardContent>
              <Grid container spacing={3} alignItems="center" ml={3}>
                <Grid item xs={3} container>
                  <Typography align="left" variant="subtitle1">
                    Name:
                  </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-start">
                  <Typography align="left">
                    {bookingDetails.booking.customer?.first_name}{" "}
                    {bookingDetails.booking.customer?.last_name}
                  </Typography>
                </Grid>
                <Grid item xs={3} container>
                  <Typography align="left" variant="subtitle1">
                    Email:
                  </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-start">
                  <Typography align="left">
                    {bookingDetails.booking.customer?.email}
                  </Typography>
                </Grid>
                <Grid item xs={3} container>
                  <Typography align="left" variant="subtitle1">
                    Phone Number:
                  </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-start">
                  <Typography align="left">
                    {bookingDetails.booking.customer?.phone_number}
                  </Typography>
                </Grid>
                <Grid item xs={3} container>
                  <Typography align="left" variant="subtitle1">
                    Nationality:
                  </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-start">
                  <Typography align="left">
                    {bookingDetails.booking.customer?.nationality ||
                      "Not Provided"}
                  </Typography>
                </Grid>
                <Grid item xs={3} container>
                  <Typography align="left" variant="subtitle1">
                    Weight:
                  </Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-start">
                  <Typography align="left">
                    {bookingDetails.booking.customer?.weight || "Not Provided"}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card sx={{ marginTop: 3 }}>
            <CardHeader
              title="Flight Segments"
              style={{ backgroundColor: "#f5f5f5" }}
            />
            <CardContent>
              <Grid container spacing={3} alignItems="center" ml={3}>
                {booking.flight_segments?.map((segment, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={3} container>
                      <Typography align="left" variant="subtitle1">
                        Trip Leg Number:
                      </Typography>
                    </Grid>
                    <Grid item xs={8} container justifyContent="flex-start">
                      <Typography align="left">
                        {segment.trip_leg_number}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} container>
                      <Typography align="left" variant="subtitle1">
                        Departure Airport:
                      </Typography>
                    </Grid>
                    <Grid item xs={8} container justifyContent="flex-start">
                      <Typography align="left">
                        {segment.departure_airport_code}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} container>
                      <Typography align="left" variant="subtitle1">
                        Arrival Airport:
                      </Typography>
                    </Grid>
                    <Grid item xs={8} container justifyContent="flex-start">
                      <Typography align="left">
                        {segment.arrival_airport_code}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} container>
                      <Typography align="left" variant="subtitle1">
                        Departing Flight Time:
                      </Typography>
                    </Grid>
                    <Grid item xs={8} container justifyContent="flex-start">
                      <Typography align="left">
                        {formatDateAndTime(segment.departing_flight_time)}
                      </Typography>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </CardContent>
          </Card>

          {passengers?.length > 0 && (
            <Card sx={{ marginTop: 3 }}>
              <CardHeader
                title="Extra Passengers"
                style={{ backgroundColor: "#f5f5f5" }}
              />
              <CardContent>
                <Grid container spacing={3} alignItems="center" ml={3}>
                  {passengers.map((passenger, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={3} container>
                        <Typography align="left" variant="subtitle1">
                          Passenger {index + 1}:
                        </Typography>
                      </Grid>
                      <Grid item xs={8} container justifyContent="flex-start">
                        <Typography align="left">
                          {passenger.first_name} {passenger.last_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} container>
                        <Typography align="left" variant="subtitle1">
                          Nationality:
                        </Typography>
                      </Grid>
                      <Grid item xs={8} container justifyContent="flex-start">
                        <Typography align="left">
                          {passenger.nationality}
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          )}

          <Typography align="left" mt={5} mb={10} variant="subtitle1">
            Thank you for booking with us. We look forward to flying with you!
          </Typography>
        </Grid>

        {/* Sidebar */}
        <Grid item xs={12} md={4}>
          {bookingDetails.booking.amount_due_later === 0 ? (
            <Card
              sx={{
                position: "sticky",
                top: "50px",
              }}
            >
              <CardHeader
                title="Payment Details"
                style={{ backgroundColor: "#f5f5f5" }}
              />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography>Base Price:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      {formatCurrency(
                        bookingDetails.booking.payments.reduce(
                          (acc, payment) => acc + (payment.amount || 0),
                          0
                        )
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Tax:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      {formatCurrency(
                        bookingDetails.booking.payments.reduce(
                          (acc, payment) => acc + (payment.tax || 0),
                          0
                        )
                      )}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} mt={1}>
                    <Typography fontWeight={600} variant="h6">
                      Grand Total:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} mt={1}>
                    <Typography fontWeight={600} variant="h6" align="right">
                      {formatCurrency(
                        bookingDetails.booking.payments.reduce(
                          (acc, payment) =>
                            acc + (payment.amount + payment.tax || 0),
                          0
                        )
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ) : (
            <Card
              sx={{
                position: "sticky",
                top: "50px",
              }}
            >
              <CardHeader
                title="Payment Details"
                style={{ backgroundColor: "#f5f5f5" }}
              />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography>Base:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      {formatCurrency(
                        bookingDetails.booking.payments.reduce(
                          (acc, payment) => acc + (payment.amount || 0),
                          0
                        )
                      )}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography>Tax:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      {formatCurrency(
                        bookingDetails.booking.payments.reduce(
                          (acc, payment) => acc + (payment.tax || 0),
                          0
                        )
                      )}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} mt={1}>
                    <Typography fontWeight={600} variant="h6">
                      Total:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} mt={1}>
                    <Typography fontWeight={600} variant="h6" align="right">
                      {formatCurrency(
                        bookingDetails.booking.payments.reduce(
                          (acc, payment) =>
                            acc + (payment.amount + payment.tax || 0),
                          0
                        )
                      )}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} mt={2}>
                    <Typography
                      align="center"
                      color="textSecondary"
                      mb={3}
                      mt={5}
                    >
                      The following amount will be due on a later date:
                    </Typography>
                  </Grid>

                  {bookingDetails.booking.scheduled_payments.map(
                    (scheduledPayment, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={6}>
                          <Typography>Base Due Later:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography align="right">
                            {formatCurrency(scheduledPayment.amount || 0)}
                          </Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Typography>Tax Due Later:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography align="right">
                            {formatCurrency(scheduledPayment.tax || 0)}
                          </Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Typography>Total Due Later:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography align="right">
                            {formatCurrency(
                              (scheduledPayment.amount || 0) +
                                (scheduledPayment.tax || 0)
                            )}
                          </Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Typography>Due on:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography align="right">
                            {formatDate(scheduledPayment.due_date)}
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    )
                  )}
                  <Grid item xs={12}>
                    <Typography align="center" color="textSecondary" mt={3}>
                      We will automatically send you a reminder email for the
                      payment.
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default BookingConfirmationPage;
