import React, { useEffect, useState } from "react";
import { Box, Button, Container, TextField } from "@mui/material";
import {
  MX_MERCHANT_CONNECTION_AIR_TOKEN,
  MX_MERCHANT_CONNECTION_AIR_TPN_NUMBER,
  MX_PAYMENT_FORM_URL,
} from "../../utils/Constants";

function FreedomToDesign() {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [ccNumber, setCcNumber] = useState("");
  const [ccExpiry, setCcExpiry] = useState("");
  const [ccCvv, setCcCvv] = useState("");

  useEffect(() => {
    const script = document.createElement("script");
    script.id = "ftd";
    script.src = MX_PAYMENT_FORM_URL;
    script.setAttribute("security_key", MX_MERCHANT_CONNECTION_AIR_TOKEN);
    script.setAttribute("merchantId", MX_MERCHANT_CONNECTION_AIR_TPN_NUMBER);
    script.onload = () => setIsScriptLoaded(true);
    document.body.appendChild(script);
  }, []);

  const postData = () => {
    return new Promise((resolve, reject) => {
      console.log("pay button clicked!");
      const scriptElement = document.getElementById("ftd");
      let hostname = "";
      if (scriptElement) {
        const scriptSrc = scriptElement.getAttribute("src");
        if (scriptSrc) {
          hostname = new URL(scriptSrc).hostname;
          console.log("Hostname:", hostname);
        } else {
          console.error("data-src attribute is missing.");
        }
      } else {
        console.error('Script element with ID "ftd" not found.');
      }
      const url = "https://" + hostname + "/api/v1/paymentCardToken";
      const securityKey = scriptElement.getAttribute("security_key");

      const payload = {
        ccNumber: ccNumber.replace(/\s+/g, ""),
        ccExpiry: ccExpiry.replace(/\//g, ""),
        ccCvv: ccCvv,
      };

      console.log("Sending request to:", url);
      console.log("Payload:", payload);
      console.log("Origin:", window.location.origin);

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "ftd-origin": window.location.origin,
          token: securityKey,
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          if (!response.ok) throw new Error("Network response was not ok.");
          return response.json();
        })
        .then((data) => {
          console.log("Payment token received:", data);
          resolve(data);
        })
        .catch((error) => {
          console.error("Fetch error:", error);
          reject(error);
        });
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Payment button pressed!");

    postData()
      .then((data) => {
        console.log("Payment token:", data);
        // Handle the token as needed, e.g., send it to your server
      })
      .catch((error) => {
        console.error("Payment error:", error);
      });
  };

  return (
    <Container>
      <h1>Payment</h1>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <TextField
            fullWidth
            id="ccnumber"
            label="Credit Card Number"
            variant="outlined"
            required
            value={ccNumber}
            onChange={(e) => setCcNumber(e.target.value)}
          />
        </Box>
        <Box mb={2}>
          <TextField
            fullWidth
            id="ccexpiry"
            label="Expiry Date"
            variant="outlined"
            required
            value={ccExpiry}
            onChange={(e) => setCcExpiry(e.target.value)}
          />
        </Box>
        <Box mb={2}>
          <TextField
            fullWidth
            id="cccvv"
            label="CVV"
            variant="outlined"
            required
            value={ccCvv}
            onChange={(e) => setCcCvv(e.target.value)}
          />
        </Box>
        <Button
          type="submit"
          id="payButton"
          variant="contained"
          color="primary"
          disabled={!isScriptLoaded} // Disable button until script is loaded
        >
          Pay
        </Button>
      </form>
    </Container>
  );
}

export default FreedomToDesign;
